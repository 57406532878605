import { RemoveSpecialChars } from "#/utils/StringUtils";

export const MENU_MATCH_STATUS = {
  cancelled: "cancelled",
  complete: "complete",
  confirmed: "confirmed",
  in_progress: "in_progress",
  new: "new",
  opt_out: "opt_out",
  removed: "removed",
};

export const COMPLETED_MM_STATUS = {
  cancelled: "cancelled",
  complete: "complete",
  confirmed: "confirmed",
  opt_out: "opt_out",
  removed: "removed",
};

export const MapSearchKey = (arr) =>
  arr?.map((i) => ({ ...i, search_key: RemoveSpecialChars(i.name) }));

export const MapItemModifierSearchKey = ({
  modifiers,
  modifier_groups,
  item_variations,
  item_families,
}) => {
  const formattedVariations = item_variations.map((iv) => {
    const found_name = item_families.find((itemFam) =>
      itemFam.item_ids.includes(iv._id),
    )?.name;
    const item_family_name = found_name || "";

    return {
      ...iv,
      item_family_name: RemoveSpecialChars(item_family_name),
      modifier_group_name: "",
      search_key: RemoveSpecialChars(iv.name),
    };
  });

  const formattedModifiers = modifiers.map((i) => {
    const found_mg = modifier_groups.find((mg) =>
      mg.modifier_ids.includes(i._id),
    )?.name;
    const modifier_group_name = found_mg || "";
    return {
      ...i,
      item_family_name: "",
      modifier_group_name: RemoveSpecialChars(modifier_group_name),
      search_key: RemoveSpecialChars(i.name),
    };
  });

  return [...formattedVariations, ...formattedModifiers];
};

export const GenerateSortedMMDropDown = ({
  potentialMatches,
  construct,
  excludedData,
  variation_to_ifam_name,
  mg_name_mod_id,
}) => {
  const potentialMatchesIds = potentialMatches.map((i) => i.catalog_object_id);
  const mappedPotentialMatches = potentialMatches
    .map((i) => {
      const foundConstruct = construct.find(
        (c) => c._id === i.catalog_object_id,
      );
      return {
        ...(foundConstruct && foundConstruct),
        ifam_name: i?.ifam_name,
      };
    })
    .filter((i) => !excludedData.includes(i._id))
    .map((item, index) => ({
      ifam_name: item?.ifam_name,
      label: item.name,
      potentialMatches: index === 0,
      value: item._id,
    }));

  const constructMapped = construct
    .filter(
      (i) =>
        !potentialMatchesIds.includes(i._id) && !excludedData.includes(i._id),
    )
    .map((item, index) => ({
      allItems: index === 0,
      ifam_name: variation_to_ifam_name ? variation_to_ifam_name[item._id] : "",
      label: item.name,
      modifier_group_name: mg_name_mod_id ? mg_name_mod_id[item._id] : "",
      value: item._id,
    }));
  return [...mappedPotentialMatches, ...constructMapped.splice(0, 100)];
};

export const SortMMResult = (result: any[], searchKey: string) => {
  return result.sort((a, b) => {
    return a.search_key.indexOf(searchKey) - b.search_key.indexOf(searchKey);
  });
};

export const MM_TUTORIAL_STEPS = {
  completed: "completed",
  connected_section: "connected_section",
  dsp_unconnected: "dsp_unconnected",
  fail_pair_item: "fail_pair_item",
  pair_item: "pair_item",
  pos_unconnected: "pos_unconnected",
  search_pair: "search_pair",
  success_pair_item: "success_pair_item",
  unconnected_section: "unconnected_section",
  welcome: "welcome",
};

export const svgs = {
  receipt: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2L3 0L0 2V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V14H18V2L15 0L12 2L9 0L6 2ZM16 14H4V17C4 17.5523 3.55228 18 3 18C2.44772 18 2 17.5523 2 17V3.07037L3 2.4037L6 4.4037L9 2.4037L12 4.4037L15 2.4037L16 3.07037V14ZM17 18H5.82929C5.93985 17.6872 6 17.3506 6 17V16H18V17C18 17.5523 17.5523 18 17 18Z"
        fill="#27C499"
      />
    </svg>
  ),

  link: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  ),

  "add-person": (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10660_110786)">
        <path
          d="M15 15.8125H7C5.93913 15.8125 4.92172 16.2339 4.17157 16.9841C3.42143 17.7342 3 18.7516 3 19.8125V21.8125"
          stroke="#27C499"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 11.8125C13.2091 11.8125 15 10.0216 15 7.8125C15 5.60336 13.2091 3.8125 11 3.8125C8.79086 3.8125 7 5.60336 7 7.8125C7 10.0216 8.79086 11.8125 11 11.8125Z"
          stroke="#27C499"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19 15.8125V23.8125"
          stroke="#27C499"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23 19.8125H15"
          stroke="#27C499"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10660_110786">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.8125)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  location: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97487 8.97487C11.3417 7.60804 11.3417 5.39196 9.97487 4.02513C8.60804 2.65829 6.39196 2.65829 5.02513 4.02513C3.65829 5.39196 3.65829 7.60804 5.02513 8.97487L7.5 11.4497L9.97487 8.97487ZM7.5 14.2782L3.61091 10.3891C1.46303 8.2412 1.46303 4.7588 3.61091 2.61091C5.7588 0.46303 9.2412 0.46303 11.3891 2.61091C13.537 4.7588 13.537 8.2412 11.3891 10.3891L7.5 14.2782ZM7.5 8C6.67157 8 6 7.32843 6 6.5C6 5.67157 6.67157 5 7.5 5C8.32843 5 9 5.67157 9 6.5C9 7.32843 8.32843 8 7.5 8ZM16.5 20.4497L18.9749 17.9749C20.3417 16.608 20.3417 14.392 18.9749 13.0251C17.608 11.6583 15.392 11.6583 14.0251 13.0251C12.6583 14.392 12.6583 16.608 14.0251 17.9749L16.5 20.4497ZM20.3891 19.3891L16.5 23.2782L12.6109 19.3891C10.463 17.2412 10.463 13.7588 12.6109 11.6109C14.7588 9.46303 18.2412 9.46303 20.3891 11.6109C22.537 13.7588 22.537 17.2412 20.3891 19.3891ZM16.5 17C15.6716 17 15 16.3284 15 15.5C15 14.6716 15.6716 14 16.5 14C17.3284 14 18 14.6716 18 15.5C18 16.3284 17.3284 17 16.5 17Z"
        fill="#27C499"
      />
    </svg>
  ),
  id: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 16.5H13.5C14.6935 16.5 15.8381 16.0259 16.682 15.182C17.5259 14.3381 18 13.1935 18 12C18 10.8065 17.5259 9.66193 16.682 8.81802C15.8381 7.97411 14.6935 7.5 13.5 7.5H11.25V16.5Z"
        stroke="#27C499"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 7.5V16.5"
        stroke="#27C499"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3636 3H3.63636C2.73262 3 2 4.00736 2 5.25V18.75C2 19.9926 2.73262 21 3.63636 21H20.3636C21.2674 21 22 19.9926 22 18.75V5.25C22 4.00736 21.2674 3 20.3636 3Z"
        stroke="#27C499"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  magic: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 14.168L16.667 16.668"
        stroke="#80849C"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2965 3.27825L12.7618 7.41108L15.732 10.3222L11.9453 12.0419L10.0945 15.7664L7.28878 12.6964L3.1747 12.087L5.22743 8.46997L4.53561 4.36897L8.60997 5.20349L12.2965 3.27825Z"
        stroke="#80849C"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  largeArrow: (
    <svg
      width="10"
      height="38"
      viewBox="0 0 10 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L9 19L1 37" stroke="#AEB2C6" />
    </svg>
  ),
  categories: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3H9V9H15V3Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 10C12 12 6 12 6 14" stroke="white" strokeWidth="2" />
      <path d="M12 10C12 12 18 12 18 14" stroke="white" strokeWidth="2" />
      <path
        d="M21 15H15V21H21V15Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15H3V21H9V15Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  menu: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  error: (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="32" fill="#C4274D" />
    </svg>
  ),
  grid: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 11.667H11.6667V17.5003H17.5V11.667Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 11.667H2.5V17.5003H8.33333V11.667Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  staggered: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66669 5H17.5"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 10H17.5"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 15H17.5"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5H2.50833"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10H2.50833"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 15H2.50833"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  doubleCheck: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7L12 12M7 17L2 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 7L12 17L7 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevronDouble: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 p-2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  ),
  success: (
    <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20.5" r="20" fill="#27C499" />
      <path
        d="M28 14.5L17 25.5L12 20.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  circle: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 4L12 14.01L9 11.01"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  dollar: (
    <svg
      width="11"
      height="18"
      fill="currentColor"
      viewBox="0 0 11 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05859 17.1621V15.1992C4.19271 15.1797 3.41797 15.0983 2.73438 14.9551C2.05729 14.8053 1.4388 14.6068 0.878906 14.3594V11.7812C1.43229 12.0482 2.0931 12.2923 2.86133 12.5137C3.62956 12.7285 4.36198 12.8587 5.05859 12.9043V9.87695C4.02995 9.47331 3.20638 9.08268 2.58789 8.70508C1.97591 8.32096 1.5332 7.89779 1.25977 7.43555C0.992839 6.9668 0.859375 6.4069 0.859375 5.75586C0.859375 5.0918 1.03516 4.51888 1.38672 4.03711C1.74479 3.54883 2.23958 3.1582 2.87109 2.86523C3.5026 2.57227 4.23177 2.38997 5.05859 2.31836V0.824219H6.39648V2.2793C7.15169 2.30534 7.85482 2.39323 8.50586 2.54297C9.16341 2.6862 9.80794 2.89779 10.4395 3.17773L9.52148 5.46289C8.98763 5.24154 8.45052 5.06901 7.91016 4.94531C7.3763 4.82161 6.87174 4.74023 6.39648 4.70117V7.58203C7.11914 7.84896 7.80599 8.13867 8.45703 8.45117C9.10807 8.76367 9.63867 9.16081 10.0488 9.64258C10.459 10.1243 10.6641 10.7559 10.6641 11.5371C10.6641 12.5072 10.3092 13.321 9.59961 13.9785C8.89648 14.6361 7.82878 15.0299 6.39648 15.1602V17.1621H5.05859ZM6.39648 12.8359C6.85221 12.7578 7.1875 12.6243 7.40234 12.4355C7.61719 12.2402 7.72461 11.9896 7.72461 11.6836C7.72461 11.5013 7.67578 11.3385 7.57812 11.1953C7.48047 11.0521 7.33073 10.9186 7.12891 10.7949C6.93359 10.6647 6.68945 10.5345 6.39648 10.4043V12.8359ZM5.05859 7.03516V4.74023C4.79167 4.7793 4.5638 4.8444 4.375 4.93555C4.1862 5.02018 4.04297 5.13086 3.94531 5.26758C3.84766 5.4043 3.79883 5.56706 3.79883 5.75586C3.79883 5.94466 3.84115 6.11068 3.92578 6.25391C4.01693 6.39714 4.15365 6.5306 4.33594 6.6543C4.52474 6.77799 4.76562 6.90495 5.05859 7.03516Z"
        fill="currentColor"
      />
    </svg>
  ),
  chevronThickDown: (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L8 8L14 2"
        stroke="#0B1130"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  checkList: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.333 6.66602L27.9997 6.66602"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9.33268L6.66667 11.9993L12 6.66602"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 22.6667L6.66667 25.3333L12 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 20L27.9997 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 12L22.6663 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 25.334H22.6663"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  percentage: (
    <svg
      width="15"
      height="16"
      fill="currentColor"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.89648 0.517578C3.87305 0.517578 4.61198 0.908203 5.11328 1.68945C5.62109 2.46419 5.875 3.5612 5.875 4.98047C5.875 6.39974 5.63086 7.50651 5.14258 8.30078C4.6543 9.08854 3.9056 9.48242 2.89648 9.48242C1.95898 9.48242 1.23958 9.08854 0.738281 8.30078C0.24349 7.50651 -0.00390625 6.39974 -0.00390625 4.98047C-0.00390625 3.5612 0.233724 2.46419 0.708984 1.68945C1.18424 0.908203 1.91341 0.517578 2.89648 0.517578ZM2.89648 1.67969C2.36914 1.67969 1.98177 1.95638 1.73438 2.50977C1.48698 3.05664 1.36328 3.88021 1.36328 4.98047C1.36328 6.08073 1.48698 6.91081 1.73438 7.4707C1.98177 8.0306 2.36914 8.31055 2.89648 8.31055C3.44336 8.31055 3.85026 8.0306 4.11719 7.4707C4.38411 6.91081 4.51758 6.08073 4.51758 4.98047C4.51758 3.88672 4.38411 3.06315 4.11719 2.50977C3.85677 1.95638 3.44987 1.67969 2.89648 1.67969ZM11.9395 0.722656L4.01953 15H2.59375L10.5137 0.722656H11.9395ZM11.5586 6.24023C12.5286 6.24023 13.2676 6.63086 13.7754 7.41211C14.2832 8.18685 14.5371 9.28385 14.5371 10.7031C14.5371 12.1159 14.293 13.2194 13.8047 14.0137C13.3164 14.8014 12.5677 15.1953 11.5586 15.1953C10.6146 15.1953 9.89518 14.8014 9.40039 14.0137C8.9056 13.2194 8.6582 12.1159 8.6582 10.7031C8.6582 9.28385 8.89258 8.18685 9.36133 7.41211C9.83659 6.63086 10.569 6.24023 11.5586 6.24023ZM11.5586 7.41211C11.0312 7.41211 10.6439 7.68555 10.3965 8.23242C10.1491 8.7793 10.0254 9.60286 10.0254 10.7031C10.0254 11.8034 10.1491 12.6335 10.3965 13.1934C10.6439 13.7467 11.0312 14.0234 11.5586 14.0234C12.1055 14.0234 12.5124 13.75 12.7793 13.2031C13.0462 12.6497 13.1797 11.8164 13.1797 10.7031C13.1797 9.60938 13.0462 8.78906 12.7793 8.24219C12.5189 7.6888 12.112 7.41211 11.5586 7.41211Z"
        fill="currentColor"
      />
    </svg>
  ),
  type: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7V4H20V7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 20H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4V20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevronWide: (
    <svg
      width="10"
      height="38"
      viewBox="0 0 10 38"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L9 19L1 37" stroke="currentColor" />
    </svg>
  ),
  note: (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11.5V1.5H1V17.5H11M17 11.5V12L11.5 17.5H11M17 11.5H11V17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  timer: (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 5.5L18.1575 7.34383M18.1575 7.34383C16.7098 5.89571 14.7095 5 12.5 5C8.08172 5 4.5 8.58172 4.5 13C4.5 17.4183 8.08172 21 12.5 21C16.9183 21 20.5 17.4183 20.5 13C20.5 10.7912 19.6049 8.79149 18.1575 7.34383Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9V13L14.5 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2H15.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  arrowControls: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9974 4.99968L6.66406 8.33301H13.3307L9.9974 4.99968Z"
        fill="currentColor"
      />
      <path
        d="M9.9974 15.0003L6.66406 11.667H13.3307L9.9974 15.0003Z"
        fill="currentColor"
      />
    </svg>
  ),
  list: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 4.16797H10.8333"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M15.8359 15.8346L18.3359 13.3346M15.8359 15.8346L13.3359 13.3346M15.8359 15.8346V4.16797"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10H10.8333"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M2.5 15.832H9.16667"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  ),
  grabber: (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.919271 1.66667C1.37951 1.66667 1.7526 1.29357 1.7526 0.833333C1.7526 0.373096 1.37951 0 0.919271 0C0.459034 0 0.0859375 0.373096 0.0859375 0.833333C0.0859375 1.29357 0.459034 1.66667 0.919271 1.66667Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
      <path
        d="M0.919271 5.83073C1.37951 5.83073 1.7526 5.45763 1.7526 4.9974C1.7526 4.53716 1.37951 4.16406 0.919271 4.16406C0.459034 4.16406 0.0859375 4.53716 0.0859375 4.9974C0.0859375 5.45763 0.459034 5.83073 0.919271 5.83073Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
      <path
        d="M0.919271 10.0026C1.37951 10.0026 1.7526 9.62951 1.7526 9.16927C1.7526 8.70903 1.37951 8.33594 0.919271 8.33594C0.459034 8.33594 0.0859375 8.70903 0.0859375 9.16927C0.0859375 9.62951 0.459034 10.0026 0.919271 10.0026Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
      <path
        d="M5.08333 1.66667C5.54357 1.66667 5.91667 1.29357 5.91667 0.833333C5.91667 0.373096 5.54357 0 5.08333 0C4.6231 0 4.25 0.373096 4.25 0.833333C4.25 1.29357 4.6231 1.66667 5.08333 1.66667Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
      <path
        d="M5.08333 5.83073C5.54357 5.83073 5.91667 5.45763 5.91667 4.9974C5.91667 4.53716 5.54357 4.16406 5.08333 4.16406C4.6231 4.16406 4.25 4.53716 4.25 4.9974C4.25 5.45763 4.6231 5.83073 5.08333 5.83073Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
      <path
        d="M5.08333 10.0026C5.54357 10.0026 5.91667 9.62951 5.91667 9.16927C5.91667 8.70903 5.54357 8.33594 5.08333 8.33594C4.6231 8.33594 4.25 8.70903 4.25 9.16927C4.25 9.62951 4.6231 10.0026 5.08333 10.0026Z"
        fill="currentColor"
        fill-opacity="0.64"
      />
    </svg>
  ),
};

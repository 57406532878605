import mixpanelPlugin from "@analytics/mixpanel";
import Analytics from "analytics";

export const events = {
  SUBSCRIPTION_STARTED: "SUBSCRIPTION_STARTED",
  SUBSCRIPTION_COMPLETED: "SUBSCRIPTION_COMPLETED",
  AI_DESCRIPTION_STARTED: "AI_DESCRIPTION_STARTED",
  AI_DESCRIPTION_GENERATED: "AI_DESCRIPTION_GENERATED",
  AI_DESCRIPTION_REJECTED: "AI_DESCRIPTION_REJECTED",
  AI_DESCRIPTION_SAVED: "AI_DESCRIPTION_SAVED",
  DSP_CONNECTED: "DSP_CONNECTED",
  DSP_BOUND: "DSP_BOUND",
  DSP_PUBLISH: "DSP_PUBLISH",
  DELETED_CATEGORY: "DELETED_CATEGORY",
  UPDATED_ITEM: "UPDATED_ITEM",
};

export const journies = {
  ONBOARDING: {
    MEMBER_SIGN_UP: "MEMBER_SIGN_UP",
    COMPLETED_POS_ONBOARDING: "COMPLETED_POS_ONBOARDING",
  },
};

const plugins = [];

if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  plugins.push(
    mixpanelPlugin({
      token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
    }),
  );
}

export const analytics = Analytics({
  app: "stream-dashboard-web",
  plugins,
});

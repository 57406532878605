import _ from "lodash";

export const CapitalizeFirstLetter = (string: string) => {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const RemoveSpecialChars = (str) =>
  str?.replace(/[^a-zA-Z ]/g, "")?.toLowerCase() || "";

export function ValidPassword(password) {
  return password?.length >= 8 && password?.length <= 40;
}

export const capitalizeEachWord = (s?: string | null) => {
  if (!s) return "";
  return s
    ?.split("_")
    .map((s) => _.capitalize(s))
    .join(" ");
};

export function convertToSentence(inputString) {
  if (!inputString) return "";
  const sentence = inputString.split("_").join(" ");
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function convertToCapsSentence(inputString) {
  if (!inputString) return "";
  return inputString
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
